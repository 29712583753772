 const table = [
    {
        src : "images/itnarery/table/day1.jpeg",
        title: " Click here to download Day-1",
        key:1,
        
    },
    {
        src : "images/itnarery/table/day2.jpeg",
        title: "Click here to download Day-2",
        key:2,
    },
    {
        src : "images/itnarery/table/day3.jpeg",
        title: "Click here to download Day-3",
        key:3,
    }
 ];

 export default table;
