const Team1 = [
    {
        name:"Harsh Chauhan",
        src:"/images/Team/cu01.jpeg"
    },
    {
        name:"Priyanshu",
        src:"/images/Team/cu02.jpeg"
    },
    {
        name:"Ayush Jain",
        src:"/images/Team/cu03.jpeg"
    },
    
    // {
    //     name:"rggda",
    //     src:"/images/Team/cu05.jpeg"
    // },

    // {
    //     name:"rggda",
    //     src:"/images/Team/cu07.jpeg"
    // },
    
    // {
    //     name:"rggda",
    //     src:"/images/Team/cu09.jpeg"
    // },
    {
        name:"Harshit ",
        src:"/images/Team/coor03.jpeg"
    },
   
    {
        name:"Rakshita",
        src:"/images/Team/cu12.jpeg"
    },
    {
        name:"Saraswati Thapa",
        src:"/images/Team/cu04.jpeg"
    },
    {
        name:"Mayank Sharma",
        src:"/images/Team/coor05.jpeg"
    },
];
const Coordinaters = [
    {
        name:"Bharat Kaushik",
        src:"/images/Team/coor01.jpeg"
    },
    {
        name:"Yash Chandola",
        src:"/images/Team/coor02.jpeg"
    },
    
    // {
    //     name:"fffsa",
    //     src:"/images/Team/coor04.jpeg"
    // },
    
    {
        name:"Mayank Suri",
        src:"/images/Team/coor06.jpeg"
    },
    // {
    //     name:"rggda",
    //     src:"/images/Team/coor07.jpeg"
    // },
    // {
    //     name:"rggda",
    //     src:"/images/Team/coor08.jpeg"
    // },
    // {
    //     name:"rggda",
    //     src:"/images/Team/coor09.jpeg"
    // },
    {
        name:"Gauransh",
        src:"/images/Team/Gauransh.png"
    },
    {
        name:"Sanket Pathak",
        src:"/images/Team/coor11.jpeg"
    },
    
    {
        name:"Aryan Pradhan",
        src:"/images/Team/coor12.jpeg"
    },
    
    {
        name:"Palak Tiwari",
        src:"/images/Team/cu10.jpeg"
    },
    {
        name:"Anveshika Bhat",
        src:"/images/Team/cu08.jpeg"
    },
    // {
    //     name:"Prashant",
    //     src:"/images/Team/prh01.png"
    // },
    
];


export default Team1;
export {Coordinaters};