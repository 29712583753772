import React from "react";

function Sponsors() {
	
	return (
		<>
			<div className="h-[100vh] flex justify-center items-center">
				<h2 className="font-semibold font-mono text-white text-3xl">COMING SOON...</h2>
			</div>
		</>
	);
}

export default Sponsors;
