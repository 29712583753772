/* eslint-disable jsx-a11y/img-redundant-alt */

function StarNight() {
  return (
    <div className=" flex justify-center ">
      {/* <img src={process.env.PUBLIC_URL + "/images/home/GuideLine.jpeg"} alt="image"/> */}
      <img className="lg:w-[20em]" src={process.env.PUBLIC_URL + "/images/home/2024.png"} alt="poster"/>
    </div>
  )
}

export default StarNight
